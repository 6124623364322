export const size = {
	smallDevices: '576px',
	mediumDevices: '768px',
	largeDevices: '992px',
	extraLargeDevices: '1200px'
}

export const device = {
	mobiles: `min-width: ${size.smallDevices}`,
	tablets: `min-width: ${size.mediumDevices}`,
	desktops: `min-width: ${size.largeDevices}`,
	largeDesktops: `min-width: ${size.extraLargeDevices}`
}
